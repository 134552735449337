import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Section, BreadCrumb, SectionPageTitle} from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCard from "../components/ArticleList"
import BreakpointUp from "../components/Media/BreakpointUp"

const ArticleGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  ${BreakpointUp.sm`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${BreakpointUp.lg`
    grid-template-columns: repeat(3, 1fr);
  `}
`
const ListTag = styled.div`
  margin:0 -5px 30px -5px;
  text-align:center;
  &:before{
    width:100%;
    height:1px;
    position:absolute;
    top:50%;
    left:0;
    right:0;
    transform:translateY(-50%);
    background-color:#E5E5E5;
  }
`
const TagItem = styled.div`
  background: ${props=>props.bg};
  color: ${props=>props.color};
  display: inline-block;
  border: 1px solid #E5E5E5;
  background: #fff;
  color: #231F20;
  font-size: 14px;
  line-height: 28px;
  margin: 10px 5px;
  cursor:pointer;
  padding: 2px 10px;
  ${BreakpointUp.sm`
    padding: 5px 15px;
  `}
	&:hover {
    background-color:#F99207;
    color:#fff;
	}
`
class ArticlePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'all',
      seletedArticles: this.props.data.allContentfulArticle.edges
    }
    this.selectTab = this.selectTab.bind(this)
  }
  selectTab(data) {
    const  articles  = this.props.data.allContentfulArticle.edges
    if (data==="all") {
      this.setState({
        seletedArticles: this.props.data.allContentfulArticle.edges   
      })
    } else if (data === "general") {
      let tempArticles = []
      articles.forEach(function(item){
        if(!item.node.category) {
          tempArticles.push(item)
        }
      })
      this.setState({
        seletedArticles: tempArticles
      })
    } else {
      let tempArticles = []
      articles.forEach(function(item){
        if(item.node.category && item.node.category.name===data) {
          tempArticles.push(item)
        }
      })
      this.setState({
        seletedArticles: tempArticles
      })
    }
  }
  render() {
    const { data } = this.props
    const pageData = data.contentfulArticleListPage
    const articles = data.allContentfulArticle
    const categories = data.allContentfulProductCategory
    let featuredArticleList = []
    articles.edges.forEach(function(item){
      if(item.node.featuredArticle) {
        featuredArticleList.push(item)
      }
    })
    const { seletedArticles } = this.state
    return(
      <Layout>
        <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
        <Section pt="140px" pb="90px" xpt="110px" xpb="60px" bg="#fff">
          <div className="container">      
            <BreadCrumb><Link to='/'>Home</Link>/<span>Blog</span></BreadCrumb>   
            <SectionPageTitle maxWidth="1120px" mt="20px">Explore Our All Articles</SectionPageTitle>     
            <ListTag>
              <TagItem onClick={()=>this.selectTab('all')} color="#030504" bg="#fff">All Articles</TagItem>
              <TagItem onClick={()=>this.selectTab('general')} color="#030504" bg="#fff">General</TagItem>
              {
                categories.edges.map((item, i) => {
                  return(
                    <TagItem onClick={()=>this.selectTab(item.node.name)} color="#030504" bg="#fff">{item.node.name}</TagItem>
                  )
                })
              }
            </ListTag> 
            <ArticleGrid>
              {
                seletedArticles.map((item, i) => {
                  return(
                    <ArticleCard data={item.node} />
                  )
                })
              }
            </ArticleGrid>  
          </div>
        </Section>  
      </Layout>
    )
  }
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageQuery {
    contentfulArticleListPage {
      metaTitle
      metaDescription
      secondSectionTitle
    }
    allContentfulArticle(sort: {fields: createdAt}) {
      edges {
        node {
          thumbnail {
            fluid {
              base64
              src
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          featuredArticle
          createdAt(fromNow: true)
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          url
          category {
            name
          }
        }
      }
    }
    allContentfulProductCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`